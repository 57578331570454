/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getNewAuthToken } from '../fn/authentication/get-new-auth-token';
import { GetNewAuthToken$Params } from '../fn/authentication/get-new-auth-token';
import { GetNewAuthTokenResponse } from '../models/get-new-auth-token-response';
import { getNewRefreshToken } from '../fn/authentication/get-new-refresh-token';
import { GetNewRefreshToken$Params } from '../fn/authentication/get-new-refresh-token';
import { GetNewRefreshTokenResponse } from '../models/get-new-refresh-token-response';
import { initPasswordReset } from '../fn/authentication/init-password-reset';
import { InitPasswordReset$Params } from '../fn/authentication/init-password-reset';
import { invalidateAllRefreshTokens } from '../fn/authentication/invalidate-all-refresh-tokens';
import { InvalidateAllRefreshTokens$Params } from '../fn/authentication/invalidate-all-refresh-tokens';
import { invalidateRefreshToken } from '../fn/authentication/invalidate-refresh-token';
import { InvalidateRefreshToken$Params } from '../fn/authentication/invalidate-refresh-token';
import { login } from '../fn/authentication/login';
import { Login$Params } from '../fn/authentication/login';
import { LoginResponse } from '../models/login-response';
import { resetPassword } from '../fn/authentication/reset-password';
import { ResetPassword$Params } from '../fn/authentication/reset-password';

@Injectable({ providedIn: 'root' })
export class AuthenticationApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `login()` */
  static readonly LoginPath = '/management/v0/auth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: Login$Params, context?: HttpContext): Observable<StrictHttpResponse<LoginResponse>> {
    return login(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: Login$Params, context?: HttpContext): Observable<LoginResponse> {
    return this.login$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginResponse>): LoginResponse => r.body)
    );
  }

  /** Path part for operation `getNewAuthToken()` */
  static readonly GetNewAuthTokenPath = '/management/v0/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewAuthToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewAuthToken$Response(params: GetNewAuthToken$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNewAuthTokenResponse>> {
    return getNewAuthToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewAuthToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewAuthToken(params: GetNewAuthToken$Params, context?: HttpContext): Observable<GetNewAuthTokenResponse> {
    return this.getNewAuthToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNewAuthTokenResponse>): GetNewAuthTokenResponse => r.body)
    );
  }

  /** Path part for operation `initPasswordReset()` */
  static readonly InitPasswordResetPath = '/management/v0/auth/init-password-reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initPasswordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initPasswordReset$Response(params: InitPasswordReset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return initPasswordReset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initPasswordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initPasswordReset(params: InitPasswordReset$Params, context?: HttpContext): Observable<void> {
    return this.initPasswordReset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `resetPassword()` */
  static readonly ResetPasswordPath = '/management/v0/auth/reset-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: ResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: ResetPassword$Params, context?: HttpContext): Observable<void> {
    return this.resetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getNewRefreshToken()` */
  static readonly GetNewRefreshTokenPath = '/management/v0/auth/refresh-tokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewRefreshToken$Response(params: GetNewRefreshToken$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNewRefreshTokenResponse>> {
    return getNewRefreshToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getNewRefreshToken(params: GetNewRefreshToken$Params, context?: HttpContext): Observable<GetNewRefreshTokenResponse> {
    return this.getNewRefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNewRefreshTokenResponse>): GetNewRefreshTokenResponse => r.body)
    );
  }

  /** Path part for operation `invalidateRefreshToken()` */
  static readonly InvalidateRefreshTokenPath = '/management/v0/auth/refresh-tokens/invalidate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invalidateRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invalidateRefreshToken$Response(params: InvalidateRefreshToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return invalidateRefreshToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invalidateRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invalidateRefreshToken(params: InvalidateRefreshToken$Params, context?: HttpContext): Observable<void> {
    return this.invalidateRefreshToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `invalidateAllRefreshTokens()` */
  static readonly InvalidateAllRefreshTokensPath = '/management/v0/auth/refresh-tokens/invalidate-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invalidateAllRefreshTokens()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invalidateAllRefreshTokens$Response(params: InvalidateAllRefreshTokens$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return invalidateAllRefreshTokens(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `invalidateAllRefreshTokens$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invalidateAllRefreshTokens(params: InvalidateAllRefreshTokens$Params, context?: HttpContext): Observable<void> {
    return this.invalidateAllRefreshTokens$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { editDetails } from '../fn/user/edit-details';
import { EditDetails$Params } from '../fn/user/edit-details';
import { editEmail } from '../fn/user/edit-email';
import { EditEmail$Params } from '../fn/user/edit-email';
import { editPassword } from '../fn/user/edit-password';
import { EditPassword$Params } from '../fn/user/edit-password';
import { getMe } from '../fn/user/get-me';
import { GetMe$Params } from '../fn/user/get-me';
import { Me } from '../models/me';

@Injectable({ providedIn: 'root' })
export class UserApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMe()` */
  static readonly GetMePath = '/management/v0/users/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe$Response(params?: GetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<Me>> {
    return getMe(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMe(params?: GetMe$Params, context?: HttpContext): Observable<Me> {
    return this.getMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<Me>): Me => r.body)
    );
  }

  /** Path part for operation `editDetails()` */
  static readonly EditDetailsPath = '/management/v0/users/me/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDetails$Response(params: EditDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDetails(params: EditDetails$Params, context?: HttpContext): Observable<void> {
    return this.editDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `editEmail()` */
  static readonly EditEmailPath = '/management/v0/users/me/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editEmail$Response(params: EditEmail$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editEmail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editEmail(params: EditEmail$Params, context?: HttpContext): Observable<void> {
    return this.editEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `editPassword()` */
  static readonly EditPasswordPath = '/management/v0/users/me/password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPassword$Response(params: EditPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editPassword(params: EditPassword$Params, context?: HttpContext): Observable<void> {
    return this.editPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

import { roadMapLineIcon } from './svg/road-map-line';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideMarkdown } from 'ngx-markdown';
import { environment } from 'src/environment/environment';

import { ApiModule as BackendApiModule } from './api/backend-connector/api.module';
import { ApiModule as ConversationAgentApiModule } from './api/conversation-agent-connector/api.module';
import { ApiModule as ConversationBackendApiModule } from './api/conversation-backend-connector/api.module';
import { ApiModule as ConversationBackendMgmtApiModule } from './api/conversation-backend-mgmt-connector/api.module';
import { DynamicImportLoader } from './i18n/dynamic-import-loader';
import { addLineIcon } from './svg/add-line';
import { aiIcon } from './svg/ai';
import { arrowBackLineIcon } from './svg/arrow-back-line';
import { arrowRightIcon } from './svg/arrow-right';
import { arrowUpIcon } from './svg/arrow-up';
import { bodoniMiniIcon } from './svg/bodoni-mini';
import { checkIcon } from './svg/check';
import { checkSourcesIcon } from './svg/check-sources';
import { closeFillIcon } from './svg/close-fill';
import { compassLineIcon } from './svg/compass-line';
import { deleteBinLineIcon } from './svg/delete-bin-line';
import { informationLineIcon } from './svg/information-line';
import { linkLineIcon } from './svg/link-line';
import { logoutIcon } from './svg/logout';
import { moreFillIcon } from './svg/more-fill';
import { newsLineIcon } from './svg/news-line';
import { quillPenLineIcon } from './svg/quill-pen-line';
import { searchIcon } from './svg/search';
import { suggestSourcesIcon } from './svg/suggest-sources';
import { unknownIcon } from './svg/unknown';
import { userIcon } from './svg/user';
import { writeBetterIcon } from './svg/write-better';

import { routes } from './app.routes';
import { askCoeditorIcon } from './svg/ask-coeditor';
import { bodoniFrostIcon } from './svg/bodoni-frost';
import { bodoniLogoIcon } from './svg/bodoni-logo';
import { checkLineIcon } from './svg/check-line';
import { grammarCheckIcon } from './svg/grammar-check';
import { historyLineIcon } from './svg/history-line';
import { minusLineIcon } from './svg/minus-line';
import { simplifyIcon } from './svg/simplify';
import { synthesizeIcon } from './svg/synthesize';
import { arrowRightUpLineIcon } from './svg/arrow-right-up-line';
import { LOCALE_ID } from '@angular/core';
import { handIcon } from './svg/hand';
import { shapeLineIcon } from './svg/shape-line';
import { listUnorderedIcon } from './svg/list-unordered';
import { indeterminateCircleLineIcon } from './svg/indeterminate-circle-line';
import { focusModeIcon } from './svg/focus-mode';
import { eraserLineIcon } from './svg/eraser-line';
import { zoomInLineIcon } from './svg/zoom-in-line';
import { zoomOutLineIcon } from './svg/zoom-out-line';
import { fullscreenLineIcon } from './svg/fullscreen-line';
import { gradienterLineIcon } from './svg/gradienter-line';
import { landscapeLineIcon } from './svg/landscape-line';
import { flagLineIcon } from './svg/flag-line';
import { communityLineIcon } from './svg/community-line';
import { googleLogoIcon } from './svg/google-logo';
import { bodoniPlaceholderIcon } from './svg/bodoni_placeholder';
import { thumbUpIcon } from './svg/thumb-up';
import { thumbDownIcon } from './svg/thumb-down';
import { errorWarningLineIcon } from './svg/error-warning-line';

const svgIcons = [
  addLineIcon,
  aiIcon,
  arrowBackLineIcon,
  arrowRightIcon,
  arrowRightUpLineIcon,
  arrowUpIcon,
  askCoeditorIcon,
  bodoniFrostIcon,
  bodoniLogoIcon,
  bodoniMiniIcon,
  bodoniPlaceholderIcon,
  checkIcon,
  checkLineIcon,
  checkSourcesIcon,
  closeFillIcon,
  communityLineIcon,
  compassLineIcon,
  deleteBinLineIcon,
  eraserLineIcon,
  errorWarningLineIcon,
  flagLineIcon,
  focusModeIcon,
  fullscreenLineIcon,
  googleLogoIcon,
  gradienterLineIcon,
  grammarCheckIcon,
  handIcon,
  historyLineIcon,
  indeterminateCircleLineIcon,
  informationLineIcon,
  landscapeLineIcon,
  linkLineIcon,
  listUnorderedIcon,
  logoutIcon,
  minusLineIcon,
  moreFillIcon,
  newsLineIcon,
  quillPenLineIcon,
  roadMapLineIcon,
  searchIcon,
  shapeLineIcon,
  simplifyIcon,
  suggestSourcesIcon,
  synthesizeIcon,
  thumbDownIcon,
  thumbUpIcon,
  unknownIcon,
  userIcon,
  writeBetterIcon,
  zoomInLineIcon,
  zoomOutLineIcon,
];
export default svgIcons;

export type SvgName = (typeof svgIcons)[number]['name'];

export const appConfig: ApplicationConfig = {
  providers: [
    provideMarkdown(),
    provideRouter(routes, withComponentInputBinding()),
    provideSvgIconsConfig({
      icons: svgIcons,
      missingIconFallback: unknownIcon,
    }),
    { provide: LOCALE_ID, useValue: 'it-IT' },
    importProvidersFrom(
      BackendApiModule.forRoot({
        rootUrl: '/api',
      }),
      ConversationAgentApiModule.forRoot({
        rootUrl: '/api-conversation-agent',
      }),
      ConversationBackendApiModule.forRoot({
        rootUrl: '/api-conversation-backend',
      }),
      ConversationBackendMgmtApiModule.forRoot({
        rootUrl: '/api-conversation-backend',
      }),
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLang,
        loader: {
          provide: TranslateLoader,
          useClass: DynamicImportLoader,
        },
      }),
      BrowserAnimationsModule,
      HttpClientModule,
    ),
  ],
};

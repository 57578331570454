/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createUser } from '../fn/admin/create-user';
import { CreateUser$Params } from '../fn/admin/create-user';
import { CreateUserResponse } from '../models/create-user-response';
import { disableUser } from '../fn/admin/disable-user';
import { DisableUser$Params } from '../fn/admin/disable-user';
import { editDetailsAdmin } from '../fn/admin/edit-details-admin';
import { EditDetailsAdmin$Params } from '../fn/admin/edit-details-admin';
import { editEmailAdmin } from '../fn/admin/edit-email-admin';
import { EditEmailAdmin$Params } from '../fn/admin/edit-email-admin';
import { enableUser } from '../fn/admin/enable-user';
import { EnableUser$Params } from '../fn/admin/enable-user';
import { getUser } from '../fn/admin/get-user';
import { GetUser$Params } from '../fn/admin/get-user';
import { listUsers } from '../fn/admin/list-users';
import { ListUsers$Params } from '../fn/admin/list-users';
import { UserItemAdmin } from '../models/user-item-admin';
import { UserItemAdminResponsePage } from '../models/user-item-admin-response-page';

@Injectable({ providedIn: 'root' })
export class AdminApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listUsers()` */
  static readonly ListUsersPath = '/management/v0/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUsers$Response(params?: ListUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<UserItemAdminResponsePage>> {
    return listUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listUsers(params?: ListUsers$Params, context?: HttpContext): Observable<UserItemAdminResponsePage> {
    return this.listUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserItemAdminResponsePage>): UserItemAdminResponsePage => r.body)
    );
  }

  /** Path part for operation `createUser()` */
  static readonly CreateUserPath = '/management/v0/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: CreateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateUserResponse>> {
    return createUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: CreateUser$Params, context?: HttpContext): Observable<CreateUserResponse> {
    return this.createUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateUserResponse>): CreateUserResponse => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/management/v0/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<UserItemAdmin>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<UserItemAdmin> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserItemAdmin>): UserItemAdmin => r.body)
    );
  }

  /** Path part for operation `enableUser()` */
  static readonly EnableUserPath = '/management/v0/admin/users/{id}/enable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser$Response(params: EnableUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return enableUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableUser(params: EnableUser$Params, context?: HttpContext): Observable<void> {
    return this.enableUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `disableUser()` */
  static readonly DisableUserPath = '/management/v0/admin/users/{id}/disable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disableUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser$Response(params: DisableUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return disableUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disableUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disableUser(params: DisableUser$Params, context?: HttpContext): Observable<void> {
    return this.disableUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `editDetailsAdmin()` */
  static readonly EditDetailsAdminPath = '/management/v0/admin/users/{id}/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editDetailsAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDetailsAdmin$Response(params: EditDetailsAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editDetailsAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editDetailsAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editDetailsAdmin(params: EditDetailsAdmin$Params, context?: HttpContext): Observable<void> {
    return this.editDetailsAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `editEmailAdmin()` */
  static readonly EditEmailAdminPath = '/management/v0/admin/users/{id}/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editEmailAdmin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editEmailAdmin$Response(params: EditEmailAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return editEmailAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editEmailAdmin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editEmailAdmin(params: EditEmailAdmin$Params, context?: HttpContext): Observable<void> {
    return this.editEmailAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

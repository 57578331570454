import { TranslateLoader } from '@ngx-translate/core';
import { from } from 'rxjs';

export class DynamicImportLoader implements TranslateLoader {
  // eslint-disable-next-line class-methods-use-this
  getTranslation(lang: string) {
    const json$ = lang === 'it' ? import('./it.json') : import('./en.json');
    return from(json$.then((x) => x.default));
  }
}

import { Component } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environment/environment';
import { AuthenticationApi } from '../../api/backend-connector/services';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss',
  standalone: true,
  imports: [TranslateModule, SvgIconComponent],
})
export class WelcomePageComponent {
  readonly googleAuthLink = `${environment.apiBasePath}${AuthenticationApi.LoginFlowPath}`;
}

import { inject } from '@angular/core';
import { UsersApi } from './api/backend-connector/services';
import { firstValueFrom } from 'rxjs';
import { MainComponent } from './pages/main/main.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Routes } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';

export const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomePageComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [
      async () => {
        const usersApi = inject(UsersApi);
        try {
          await firstValueFrom(usersApi.getMe());
          return true;
        } catch (e) {
          if (e instanceof HttpErrorResponse && e.status == 401) {
            window.location.href = 'welcome';
          }

          throw e;
        }
      },
    ],
    children: [
      {
        path: 'my-articles',
        loadComponent: () => import('./pages/my-articles-page/my-articles-page.component'),
      },
      {
        path: 'coeditor/:id',
        loadComponent: () => import('./pages/cowrite-page/cowrite-page.component'),
      },
      {
        path: 'coeditor',
        loadComponent: () => import('./pages/cowrite-page/cowrite-page.component'),
      },
      {
        path: 'news/:id',
        loadComponent: () => import('./pages/topic-details-page/topic-details-page.component'),
      },
      {
        path: 'news',
        loadComponent: () => import('./pages/topics-list-page/topics-list-page.component'),
      },
      {
        path: 'news/articles/:id',
        loadComponent: () => import('./pages/article-details-page/article-details-page.component'),
      },
      {
        path: 'explore',
        loadComponent: () => import('./pages/explore-page/explore-page.component'),
      },
      {
        path: '',
        redirectTo: 'explore',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

<div class="flex flex-col size-full items-center justify-center gap-6 lg:gap-12">
  <div class="max-w-full px-3 lg:max-w-[33%] text-center">
    <span
      class="font-article-title text-2xl max-w-full md:text-3xl lg:text-4xl"
      [innerHTML]="'i18n.WelcomePageComponent.northStar' | translate"></span>
    <svg-icon
      key="bodoni-logo"
      size="xs"
      class="text-accent mb-3 lg:!text-base"></svg-icon>
  </div>

  <div class="lg:hidden flex flex-col bg-orange-100 p-3 mx-3 rounded-lg">
    <span [innerHTML]="'i18n.WelcomePageComponent.mobileWarning' | translate"></span>
  </div>

  <a
    class="rounded-lg border border-rest-border bg-white px-3 py-2 flex items-center logo-shadow cursor-pointer gap-2 hover:opacity-50 transition-all"
    [href]="googleAuthLink">
    <img
      key="google-logo"
      src="assets/svg/google-logo.svg"
      alt="Google Logo" />

    <span class="text-accent-dark font-medium text-sm">{{
      'i18n.WelcomePageComponent.loginWithGoogleAccount' | translate
    }}</span>
  </a>

  <footer class="absolute bottom-0 px-2 py-6 flex flex-col items-center text-center">
    <img
      class="w-fit ml-14"
      src="assets/svg/bodoni-prototype-logo.svg"
      alt="Bodoni Logo" />

    <span
      class="text-sm leading-5 font-medium text-rest [&_a]:underline"
      [innerHTML]="'i18n.SidebarMenu.about' | translate"></span>
  </footer>
</div>

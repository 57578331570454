/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createRoom } from '../fn/rooms/create-room';
import { CreateRoom$Params } from '../fn/rooms/create-room';
import { deleteRoom } from '../fn/rooms/delete-room';
import { DeleteRoom$Params } from '../fn/rooms/delete-room';

@Injectable({ providedIn: 'root' })
export class RoomsApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createRoom()` */
  static readonly CreateRoomPath = '/chat/v0/rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoom()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoom$Response(params: CreateRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoom$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoom(params: CreateRoom$Params, context?: HttpContext): Observable<void> {
    return this.createRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteRoom()` */
  static readonly DeleteRoomPath = '/chat/v0/rooms/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRoom()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoom$Response(params: DeleteRoom$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRoom(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRoom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoom(params: DeleteRoom$Params, context?: HttpContext): Observable<void> {
    return this.deleteRoom$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

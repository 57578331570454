import { Component, viewChild } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MenuItem, SharedModule } from 'primeng/api';
import { MenuModule, Menu } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-root-menu',
  standalone: true,
  imports: [MenuModule, SvgIconComponent, SharedModule, TooltipModule],
  templateUrl: './root-menu.component.html',
  styleUrl: './root-menu.component.scss',
})
export class RootMenuComponent {
  readonly menu = viewChild.required(Menu);

  toggleMenu(menuItems: MenuItem[], event: Event) {
    this.menu().model = menuItems;
    this.menu().toggle(event);
  }
}
